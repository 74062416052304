import React from 'react';
import { InfoWindowF, MarkerF } from '@react-google-maps/api';
import Table from 'react-bootstrap/Table';

import useToggle from '../hooks/toggle';

import MarkerIcon from '../images/tree-marker.png';

const options = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

export default function MapsMarker(props) {
  const [isOpen, onToggleIsOpen] = useToggle();

  const location = props.marker;
  const title = location.name || location.city;

  const onClickEvent = (id) => {
    window.location.href = `/gids/${id}`;
  };

  return (
    <MarkerF
      key={props.id}
      icon={MarkerIcon}
      onClick={onToggleIsOpen}
      position={{ lat: location.latitude, lng: location.longitude }}
      title={title}
    >
      {isOpen && (
        <InfoWindowF position={{ lat: location.latitude, lng: location.longitude }} onCloseClick={onToggleIsOpen}>
          <div>
            <h3>{title}</h3>
            <Table className="agenda-table">
              <thead>
                <tr>
                  <th>Praktijk</th>
                  <th>Startdatum</th>
                </tr>
              </thead>
              <tbody>
                {location.events.map((event) => (
                  <tr key={event.id} className="link-row" onClick={() => onClickEvent(event.guide_id)}>
                    <td>{event.guide.practice}</td>
                    <td>{new Date(event.start_date).toLocaleString('nl-NL', options)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </InfoWindowF>
      )}
    </MarkerF>
  );
}
